import React, { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';

import { SENDBIRD_OVERVIEW } from 'src/config-global';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);
const iconify = (name) => (
  <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  provider: icon('ic_provider'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  hotelconci: icon('ic_hotelconci'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      {
        items: [
          {
            title: '대시보드',
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
        ],
      },
      {
        subheader: '회원관리',
        items: [
          {
            title: '여행자 회원',
            path: paths.user.root,
            icon: ICONS.user,
          },
          {
            title: '제공자 회원',
            path: paths.provider.root,
            icon: ICONS.provider,
          },
        ],
      },
      {
        subheader: '서비스관리',
        items: [
          {
            title: '거래원장',
            path: paths.booking.all,
            icon: ICONS.invoice,
            children: [
              {
                title: '거래원장(App)',
                path: paths.booking.app,
              },
              {
                title: '거래원장(Web)',
                path: paths.booking.web,
              },
              {
                title: '거래원장(Hotel Concierge)',
                path: paths.booking.hotel,
              },
              {
                title: '거래원장(Online Channels)',
                path: paths.booking.online,
              },
              {
                title: '거래원장(Etc)',
                path: paths.booking.etc,
              },
            ],
          },
        ],
      },
      {
        subheader: '고객센터',
        items: [
          {
            title: '다이렉트 문의',
            path: SENDBIRD_OVERVIEW,
            icon: ICONS.chat,
            endIcon: <Iconify icon="eva:external-link-outline" width={16} color="#637381" />,
          },
          {
            title: '공지사항 관리',
            path: paths.bbs.notice.list,
            icon: <Iconify icon="solar:chat-round-dots-bold" />,
          },
          {
            title: 'FAQ 관리',
            path: paths.bbs.faq.list,
            icon: <Iconify icon="fluent:calendar-agenda-24-regular" />,
          },
        ],
      },

      {
        subheader: '플랫폼 관리',
        items: [
          {
            title: '가이드 서비스',
            path: paths.gs.root,
            icon: ICONS.tour,
          },
          {
            title: '운전기사 서비스',
            path: paths.cdc.root,
            icon: <Iconify icon="solar:user-id-bold" width={24} />,
          },
          {
            title: '공항 픽업/샌딩 서비스',
            path: paths.at.root,
            icon: <Iconify icon="fluent:airplane-landing-20-filled" width={24} />,
          },
          {
            title: '투어・체험 상품',
            path: paths.ts.root,
            icon: <Iconify icon="mingcute:bank-fill" width={24} />,
          },
          // 추 후 개발
          // {
          //   title: '컨텐츠관리',
          //   path: paths.app.main,
          //   icon: ICONS.blog,
          //   children: [
          //     { title: '여행자APP 메인', path: paths.app.main },
          //     { title: '여행자APP 서비스 인트로', path: paths.app.intro },
          //   ],
          // },
          {
            title: '시스템 관리',
            path: paths.system.root,
            icon: <Iconify icon="ant-design:setting-twotone" />,
            children: [
              { title: '다국어 코드', path: paths.system.language },
              { title: '서비스 지역', path: paths.system.serviceArea },
              { title: '공통 코드', path: paths.system.code },
              { title: '관리자 계정', path: paths.system.admin },
            ],
          },
          {
            title: '정책관리',
            path: paths.tap.root,
            icon: <Iconify icon="carbon:policy" />,
            children: [
              { title: '여행자 이용약관', path: paths.tap.edit('traveler-terms') },
              { title: '여행자 개인정보', path: paths.tap.edit('traveler-privacy') },
              { title: '버틀러(제공자) 이용약관', path: paths.tap.edit('vutler-terms') },
              { title: '버틀러(제공자) 개인정보', path: paths.tap.edit('vutler-privacy') },
            ],
          },
        ],
      },
    ],
    []
  );

  return data;
}
